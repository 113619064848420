<template>
  <div>
    <div class="header">
      <h2>
        {{ collegeDetail.college_name }}{{ collegeDetail.major_type }}项目
      </h2>
      <div class="school">
        <div class="school_l">
          <div class="logo">
            <img :src="collegeDetail.school_badge_url" alt="" />
          </div>
          <div class="school_label">
            <div class="labelTop">
              <span
                v-for="(list, index) in collegeDetail.college_attr"
                :key="index"
                >{{ list }}</span
              >
              <!-- <span>985</span>
              <span>211</span> -->
            </div>
            <div class="labelFot">
              <span
                v-for="(list, index) in collegeDetail.project_attr"
                :key="index"
                >{{ list }}</span
              >
            </div>
          </div>
        </div>

        <div class="address">
          <span
            ><img
              src="http://www.xiongsongedu.com/uploads/20210603/93a08ef8495c1776fd0341497dfcecd5.png"
              alt=""
          /></span>
          <span>{{ basicInfo.city }}</span>
        </div>
      </div>
    </div>
    <div class="xiangqng">
      <div class="other" @click="showPopup">
        <span>{{ cate }}</span>
        <span>查看该专业附近其它院校 > </span>
      </div>
      <div class="whole">
        <div class="biaoti">
          <div class="biaoti_l">
            <span class="hr"></span>
            <span class="xinxi">基本信息</span>
          </div>
          <div class="biaoti_r">
            <p @click="collection">
              <span>
                <img
                  v-if="!showSearch"
                  src="http://www.xiongsongedu.com/uploads/20210603/7ca20f0cfb01328931e98e875d4a95ee.png" />
                <img
                  v-if="showSearch"
                  src="http://www.xiongsongedu.com/uploads/20210617/f02b0feffab18f123a4d9fddb358ce49.png"
              /></span>
              <span>收藏</span>
            </p>
            <p @click="contrast(id, collegeDetail.college_name)">
              <span
                ><img
                  src="http://www.xiongsongedu.com/uploads/20210603/4fd6bd3d7ed4478520735260e86357aa.png"
                  alt=""
              /></span>
              <span>加入对比</span>
            </p>
          </div>
        </div>
        <div class="basic">
          <div class="basic_l" @click="video">
            <img :src="basicInfo.video_img_url" alt="" />
          </div>
          <div class="basic_r">
            <h2>项目简介</h2>
            <p @click="Jane">
              {{ basicInfo.description }}
             
              <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;》 </span> -->
            </p>
          </div>
        </div>
        <!-- 基本信息  视频 -->
        <van-popup v-model="videoShow">
          <video class="video" controls>
            <source
              src="https://www.bilibili.com/video/BV1Cp4y1q7F2?spm_id_from=333.851.b_62696c695f7265706f72745f646f756761.9"
              type="video/mp4"
            />
          </video>
          <!-- <video  controls class="video">
            <source src="https://live.bilibili.com/22341360?hotRank=0&spm_id_from=333.851.b_62696c695f7265706f72745f6c697665.4">
          </video> -->
        </van-popup>
        <div class="information">
          <form action="">
            <table>
              <tr>
                <td class="infor_l">所在地</td>
                <td class="infor_r">{{ basicInfo.province }}</td>
              </tr>
              <tr>
                <td class="infor_l">开办年份</td>
                <td class="infor_r">{{ basicInfo.start_year }}年</td>
              </tr>
              <tr>
                <td class="infor_l">国际认证</td>
                <td class="infor_r">
                  <span
                    v-for="(list, index) in basicInfo.cert_attr"
                    :key="index"
                    >{{ list }}</span
                  >
                </td>
              </tr>
              <tr>
                <td class="infor_l">上课地址</td>
                <td class="infor_r">{{ basicInfo.class_address }}</td>
              </tr>
              <tr>
                <td class="infor_l">官网</td>
                <td class="infor_r">{{ basicInfo.website }}/</td>
              </tr>
            </table>
          </form>
        </div>
      </div>
      <div class="nav">
        <div class="tab">
          <ul class="tab-tilte">
            <li @click="cur = 0" :class="{ active: cur == 0 }">招生信息</li>
            <li @click="cur = 1" :class="{ active: cur == 1 }">提前面试</li>
            <li @click="cur = 2" :class="{ active: cur == 2 }">分数线</li>
            <li @click="cur = 3" :class="{ active: cur == 3 }">复试录取</li>
          </ul>
          <ul class="tab-tilte1">
            <li @click="cur = 0" :class="{ active1: cur == 0 }"></li>
            <li @click="cur = 1" :class="{ active1: cur == 1 }"></li>
            <li @click="cur = 2" :class="{ active1: cur == 2 }"></li>
            <li @click="cur = 3" :class="{ active1: cur == 3 }"></li>
          </ul>
        </div>
        <!-- 招生信息 -->
        <div v-show="cur == 0" class="tab_l">
          <div class="recruit">
            <form action="" class="information">
              <table>
                <tr>
                  <td class="infor_l">学费</td>
                  <td class="infor_r">{{ enroll.fee == "" ? '暂无': enroll.fee}}</td>
                </tr>
                <tr>
                  <td class="infor_l">学制</td>
                  <td class="infor_r">{{ enroll.edu_system == "" ? '暂无': enroll.edu_system }}</td>
                </tr>
                <tr>
                  <td class="infor_l">招生人数</td>
                  <td class="infor_r">{{ enroll.number == "" ? '暂无': enroll.number }}人</td>
                </tr>
                <tr>
                  <td class="infor_l">是否开设提前面试</td>
                  <td class="infor_r">
                    {{
                      enroll.is_interview == 0
                        ? "否"
                        : enroll.is_interview == 1
                        ? "是"
                        : "必须提面"
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="infor_l">是否接受调剂</td>
                  <td class="infor_r">
                    {{ enroll.is_adjust == 0 ? "否" : "是" }}
                  </td>
                </tr>
                <tr>
                  <td class="infor_l">奖学金</td>
                  <td class="infor_r">400人</td>
                </tr>
                <tr>
                  <td class="infor_l">招生简章</td>
                  <td class="infor_r">
                    <a
                      v-for="(list, index) in rules"
                      :key="index"
                      class="forA"
                      :href="list.weburl"
                      ><span>{{ list.year }}</span></a
                    >
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
        <!-- 提前面试 -->
        <div v-show="cur == 1" class="tab_l">
          <div class="recruit">
            <form action="" class="information">
              <table>
                <tr>
                  <td class="infor_l">入学年份</td>
                  <td class="infor_r">{{ interview.enroll_year == "" ? '暂无': interview.enroll_year }}</td>
                </tr>
                <tr>
                  <td class="infor_l">提面批次</td>
                  <td class="infor_r">{{ interview.batch == "" ? '暂无': interview.batch+'批'}}</td>
                </tr>
                <tr>
                  <td class="infor_l">申请时间</td>
                  <td class="infor_r">{{ interview.apply_time == "" ? '暂无': interview.apply_time }}</td>
                </tr>
                <tr>
                  <td class="infor_l">面试时间</td>
                  <td class="infor_r">{{ interview.time == "" ? '暂无': interview.time }}</td>
                </tr>
                <tr>
                  <td class="infor_l">提面成绩</td>
                  <td class="infor_r">{{ interview.grade == "" ? '暂无': interview.grade }}</td>
                </tr>
                <tr>
                  <td class="infor_l">录取规则</td>
                  <td class="infor_r">{{ interview.admission_rule == "" ? '暂无' : interview.admission_rule }}</td>
                </tr>
                <tr>
                  <td class="infor_l">提面方案</td>
                  <td class="infor_r">
                    <a
                      v-for="(list, index) in plan"
                      :key="index"
                      class="forA"
                      :href="list.weburl"
                      ><span>{{ list.year }}</span></a
                    >
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
        <!-- 分数线 -->
        <div v-show="cur == 2" class="tab_l">
          <!-- A线 -->
          <div v-if="bbb">
            <div class="echarseTitle" v-if="Line.A != undefined || ''">
              <span>提前批分数线</span>
            </div>
            <div
              id="main"
              style="width: 375px; height: 300px; margin: 0.1rem auto"
            ></div>
          </div>

          <!-- B线 -->
          <div v-if="aaa">
            <div class="echarseTitle" v-if="Line.B != undefined || ''">
              <span>提前批分数线</span>
            </div>
            <div
              id="mainB"
              style="width: 375px; height: 300px; margin: 0.1rem auto"
            ></div>
          </div>
        </div>
        <!-- 复试录取 -->
        <div v-show="cur == 3" class="tab_l">
          <div class="recruit">
            <form action="" class="information">
              <table>
                <tr>
                  <td class="infor_l">复试差额</td>
                  <td class="infor_r">{{ Admission.difference  == "" ? '暂无' : Admission.difference  }}</td>
                </tr>
                <tr>
                  <td class="infor_l">报录比</td>
                  <td class="infor_r">{{ Admission.record_than == "" ? '暂无' : Admission.record_than  }}</td>
                </tr>
                <tr>
                  <td class="infor_l">复试形式</td>
                  <td class="infor_r">{{ Admission.form == "" ? '暂无' : Admission.form  }}</td>
                </tr>
                <tr>
                  <td class="infor_l">复试内容</td>
                  <td class="infor_r">{{ Admission.content == "" ? '暂无' : Admission.content  }}</td>
                </tr>
                <tr>
                  <td class="infor_l">录取平均分</td>
                  <td class="infor_r">{{ Admission.ave_score == "" ? '暂无' : Admission.ave_score  }}</td>
                </tr>
                <tr>
                  <td class="infor_l">录取人数</td>
                  <td class="infor_r">{{ Admission.admission_number == "" ? '暂无' : Admission.admission_number  }}</td>
                </tr>
                <tr>
                  <td class="infor_l">复试方案</td>
                  <td class="infor_r">
                    <a
                      v-for="(list, index) in second"
                      :key="index"
                      class="forA"
                      :href="list.weburl"
                      ><span>{{ list.year }}</span></a
                    >
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
      </div>
      <!-- 订阅号 -->
      <div class="follow">
        <div class="followBor">
          <div class="follow_l">
            <span
              ><img
                src="http://www.xiongsongedu.com/uploads/20210603/a45256d1bdc44ebae388b5d4530a57a1.png"
                alt=""
            /></span>
            <p class="followTitle">
              <span>雄松MBA 公众号</span>
              <span>点击关注，最新考讯<br />实时送达，不错过重要信息！</span>
            </p>
          </div>
          <span class="btn">关注</span>
        </div>
        <div class="followBor">
          <div class="follow_l">
            <span
              ><img
                src="http://www.xiongsongedu.com/uploads/20210603/af7bf12b66d4bde1b4d9bd09474b6c1b.png"
                alt=""
            /></span>
            <p class="followTitle">
              <span>22考研交流群</span>
              <span>添加老师微信，拉入备考群<br />考研报团，一起上岸！</span>
            </p>
          </div>
          <span
            class="btn tag-read"
            data-clipboard-text="xszk1010"
            @click="copy"
            >加群</span
          >
        </div>
      </div>

      <!-- 信息错误反馈 -->
      <div class="feedback">
        <p>
          信息有误？<span @click="backClick"> 错误上报 ></span><br />
          请花30秒告诉我们，你的纠正非常重要！
        </p>
      </div>
      <!-- 反馈弹窗 -->
      <van-popup v-model="feedshow" class="feedpopup">
        <p>错误上报</p>
        <span>请告诉我们哪里数据有误...</span>
        <textarea v-model="feedData">内容</textarea>
        <!-- <input type="text" v-model="feedData"> -->
        <div class="fankuiBtn">
          <button @click="feedcancel">取消</button>
          <button @click="Submit">提交</button>
        </div>
      </van-popup>
    </div>

    <!-- 其他专业院校弹框 -->
    <van-popup v-model="show" position="bottom" class="popup">
      <div class="popupcon">
        <span
          v-for="(list, index) in otherColl"
          :key="index"
          @click="other(list.id)"
        >
          {{ list.college_name }}
        </span>
        <!-- <span>（125200） MPA 公共管理硕士</span>
        <span>（125300） MPAcc 会计硕士</span> -->
        <p @click="cancel">取消</p>
      </div>
    </van-popup>

    <!-- 对比院校 -->
    <van-popup v-model="yuanxiao" position="top" class="duibiBor">
      <div class="duibi">
        <div class="contrast">
          <div class="contrastBor">
            <p class="contrast_l">
              <span
                ><img
                  src="http://www.xiongsongedu.com/uploads/20210603/2fa9c7bb6fc9053b54c064a9f41d9a07.png"
                  alt=""
              /></span>
              <span>院校对比（最多4所）</span>
            </p>
            <!-- <span><van-icon name="cross" color="#888888" size="20" /></span> -->
          </div>
        </div>
        <div class="yuanxiao" v-for="(list, index) in compare" :key="index">
          <span v-if="list">{{ list.name }}</span>
          <div v-if="list">
            <span @click="deldet(list.id, list.name, index)"
              ><van-icon name="cross" color="#888888" size="20"
            /></span>
          </div>
        </div>
        <!-- <div class="yuanxiao">
          <span>北京理工大学</span>
          <span><van-icon name="cross" color="#888888" size="20" /></span>
        </div> -->
        <div class="duibiBtn">
          <button @click="empty">清空</button>
          <button @click="star">开始对比</button>
        </div>
      </div>
    </van-popup>

    <!-- 右侧咨询 -->
    <div class="inquiry">
      <a
        href="http://p.qiao.baidu.com/cps/chat?siteId=9119610&userId=5449849&siteToken=d5ff7ab8a4e5e1c9d8718126f28db09b"
      >
        <img
          src="http://www.xiongsongedu.com/uploads/20210603/425321c960f123b78da8afa2fd662195.png"
          alt=""
      /></a>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import {
  CollegeDetail,
  CollegeCollect,
  CollegesameArea,
  Collegefeedback,
} from "../api/home";
import Clipboard from "clipboard";

export default {
  data() {
    return {
      active: 2,
      show: false,
      yuanxiao: false,
      cur: 0, //默认选中第一个tab
      id: this.$route.query.id,
      collegeDetail: [],
      basicInfo: [],
      cert_attr: "",
      enroll: [],
      rules: [],
      interview: [],
      plan: [],
      Admission: [],
      second: [],
      grade: [],
      videoShow: false,
      compare: [],
      idVal: "",
      showSearch: false,
      area: "",
      major_type: "",
      otherColl: [],
      Line: [],
      data1: [],
      hou: [],
      data2: [],
      aaa: true,
      bbb: true,
      cate: "",
      feedshow: false,
      feedData: "",
    };
  },
  async mounted() {
    //localStorage.removeItem("school")

    try {
      const res = await CollegeDetail({
        id: this.id,
      });

      this.collegeDetail = res.data.data;
      // ---基本信息
      this.basicInfo = this.collegeDetail.basicInfo;
      //---招生信息
      this.enroll = this.collegeDetail.enroll;
      this.rules = JSON.parse(this.enroll.rules);
      //---提前面试
      this.interview = this.collegeDetail.interview;
      this.plan = JSON.parse(this.interview.plan);
      //---分数线
      this.Line = this.collegeDetail.grade_line;

      if (this.Line.B == undefined) {
        this.aaa = false;
      }
      if (this.Line.A == undefined) {
        this.bbb = false;
      }
      //---复试录取
      this.Admission = this.collegeDetail.secondary;
      this.second = JSON.parse(this.Admission.plan);

      //---判断是否收藏
      if (this.collegeDetail.is_connect == false) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
      this.area = this.basicInfo.city_id; //---地区ID
      this.major_type = this.collegeDetail.major_type; //---专业
    } catch (e) {
      console.log(e);
    }
    if (this.major_type == "MBA") {
      this.cate = "（125100）MBA 工商管理硕士";
    } else if (this.major_type == "EMBA") {
      this.cate = "（125100）EMBA 高级管理人员工商管理硕士";
    } else if (this.major_type == "MPA") {
      this.cate = "（125200）MPA 公共管理硕士";
    } else if (this.major_type == "MPAcc") {
      this.cate = "（125300）MPAcc 会计硕士";
    } else if (this.major_type == "MTA") {
      this.cate = "（125400）MTA 旅游管理硕士";
    } else if (this.major_type == "MLIS") {
      this.cate = "（125500）MLIS 图书情报硕士";
    } else if (this.major_type == "MEM") {
      this.cate = "（125600）MEM 工程管理硕士";
    } else if (this.major_type == "MAud ") {
      this.cate = "（025700）MAud 审计硕士 ";
    }

    this.drawChart();
  },
  methods: {
    async drawChart() {
      // 基于准备好的dom，初始化echarts实例
      this.grade = this.collegeDetail;
      let myChart = this.$echarts.init(document.getElementById("main"));
      if (this.grade.grade_line.A == undefined) {
        console.log(111);
      } else {
        var gra = this.grade.grade_line.A;
        let yearArr = [];
        let totalScoreArr = [];
        let data1Arr = [];
        let data2Arr = [];
        let Grade = [];
        for (var i = 0; i < gra.length; i++) {
          yearArr.push(gra[i].Year + "年");
          Grade.push(gra[i].Grade);
        }
        Grade.forEach((item) => {
          totalScoreArr.push(item[0]);
          data1Arr.push(item[1]);
          data2Arr.push(item[2]);
        });

        // 指定图表的配置项和数据
        var option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["总分", "综合", "英语"],
          },
          grid: {
            left: "3%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: yearArr,
            // ["2021年", "2010年", "2019年"]
          },
          yAxis: {
            type: "value",
            max: 300,
          },
          series: [
            {
              name: "总分",
              type: "line",
              // data: [170, 185, 175],
              data: totalScoreArr,
              symbolSize: 8, //折线点的大小
              symbol: "circle", //折线点设置为实心点
              itemStyle: {
                normal: {
                  color: "#007751", //改变折线点的颜色
                  lineStyle: {
                    color: "#007751", //改变折线颜色
                    width: 3,
                  },
                },
              },
              label: {
                normal: {
                  fontSize: 12,
                  show: true, //折点显示数值,
                  position: "top",
                  color: "#999999",
                },
              },
            },
            {
              name: "综合",
              type: "line",
              // data: [84, 95, 80],
              data: data1Arr,
              symbolSize: 8, //折线点的大小
              symbol: "circle", //折线点设置为实心点
              itemStyle: {
                normal: {
                  color: "#666666", //改变折线点的颜色
                  lineStyle: {
                    color: "#666666", //改变折线颜色
                    width: 3,
                  },
                },
              },
              label: {
                normal: {
                  fontSize: 12,
                  show: true, //折点显示数值,
                  position: "top",
                  color: "#999999",
                },
              },
            },
            {
              name: "英语",
              type: "line",
              // data: [45, 50, 55],
              data: data2Arr,
              symbolSize: 8, //折线点的大小
              symbol: "circle", //折线点设置为实心点
              itemStyle: {
                normal: {
                  color: "#c1c1c1", //改变折线点的颜色
                  lineStyle: {
                    color: "#c1c1c1", //改变折线颜色
                    width: 3,
                  },
                },
              },
              label: {
                normal: {
                  fontSize: 12,
                  show: true, //折点显示数值,
                  position: "top",
                  color: "#999999",
                },
              },
            },
          ],
          symbolSize: 10, //折线点的大小，必须加这个，折点上方的数值才会显示
        };

        myChart.setOption(option);
      }

      let myChartB = this.$echarts.init(document.getElementById("mainB"));
      if (this.grade.grade_line.B == undefined) {
        console.log(2222);
      } else {
        var gra1 = this.grade.grade_line.B;
        let yearArr1 = [];
        let totalScoreArr1 = [];
        let data1Arr1 = [];
        let data2Arr1 = [];
        let Grade1 = [];
        for (var v = 0; v < gra1.length; v++) {
          yearArr1.push(gra1[v].Year + "年");
          Grade1.push(gra1[v].Grade);
        }
        Grade1.forEach((item) => {
          totalScoreArr1.push(item[0]);
          data1Arr1.push(item[1]);
          data2Arr1.push(item[2]);
        });
        var optionB = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["总分", "综合", "英语"],
          },
          grid: {
            left: "3%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            // data: ["2021年", "2010年", "2019年"],
            data: yearArr1,
          },
          yAxis: {
            type: "value",
            max: 300,
          },
          series: [
            {
              name: "总分",
              type: "line",
              // data: [170, 185, 175],
              data: totalScoreArr1,
              symbolSize: 8, //折线点的大小
              symbol: "circle", //折线点设置为实心点
              itemStyle: {
                normal: {
                  color: "#007751", //改变折线点的颜色
                  lineStyle: {
                    color: "#007751", //改变折线颜色
                    width: 3,
                  },
                },
              },
              label: {
                normal: {
                  fontSize: 12,
                  show: true, //折点显示数值,
                  position: "top",
                  color: "#999999",
                },
              },
            },
            {
              name: "综合",
              type: "line",
              data: data1Arr1,
              symbolSize: 8, //折线点的大小
              symbol: "circle", //折线点设置为实心点
              itemStyle: {
                normal: {
                  color: "#666666", //改变折线点的颜色
                  lineStyle: {
                    color: "#666666", //改变折线颜色
                    width: 3,
                  },
                },
              },
              label: {
                normal: {
                  fontSize: 12,
                  show: true, //折点显示数值,
                  position: "top",
                  color: "#999999",
                },
              },
            },
            {
              name: "英语",
              type: "line",
              // data: [45, 50, 55],
              data: data2Arr1,
              symbolSize: 8, //折线点的大小
              symbol: "circle", //折线点设置为实心点
              itemStyle: {
                normal: {
                  color: "#c1c1c1", //改变折线点的颜色
                  lineStyle: {
                    color: "#c1c1c1", //改变折线颜色
                    width: 3,
                  },
                },
              },
              label: {
                normal: {
                  fontSize: 12,
                  show: true, //折点显示数值,
                  position: "top",
                  color: "#999999",
                },
              },
            },
          ],
          symbolSize: 10, //折线点的大小，必须加这个，折点上方的数值才会显示
        };

        // 使用刚指定的配置项和数据显示图表。
        myChartB.setOption(optionB);
      }
    },
    //---查看附近专业其他院校
    async showPopup() {
      const joyo = await CollegesameArea({
        area_id: this.area,
        major_type: this.major_type,
      });
      this.otherColl = joyo.data.data;
      console.log(this.otherColl);
      this.show = true;
    },
    other(id) {
      this.show = false;
      this.$router.push({
        path: "/detia",
        query: {
          id: id,
        },
      });
    },
    cancel() {
      this.show = false;
    },
    contrast(id = "", name = "") {
      this.yuanxiao = true;
      // console.log(id)
      // console.log(name)
      var json = JSON.parse(localStorage.getItem("school")) || [];
      json.forEach((item) => {
        console.log(item.id);
        this.idVal = item.id;
      });
      if (this.idVal == this.id) {
        console.log("已添加");
      } else if (this.idVal != this.id) {
        json[json.length] = {
          id: id,
          name: name,
        };
      }
      console.log("json", json);

      var styJson = JSON.stringify(json);
      this.compare = json;
      localStorage.setItem("school", styJson);
    },
    //---删除院校
    deldet(listId, listName, index) {
      var school = JSON.parse(localStorage.getItem("school"));
      school.forEach((list) => {
        if (list.id == list.id) {
          this.$delete(school, index);
        }
      });
      var schools = JSON.stringify(school);
      localStorage.setItem("school", schools);
      this.compare = school;
      console.log(this.compare);
    },

    //---基本信息 视频
    video() {
      this.videoShow = true;
    },

    //---开始对比跳转
    star() {
      if (!window.localStorage.getItem("token")) {
        Toast("请先登录");
        this.$router.push("./login");
      } else {
        this.$router.push("/contrast");
      }
    },
    //---清空对比
    empty() {
      localStorage.removeItem("school");
      this.compare = JSON.parse(localStorage.getItem("school"));
    },
    //---收藏
    async collection() {
      var formData = new FormData();
      formData.append("college_id", this.id);
      console.log(formData);
      if (!window.localStorage.getItem("token")) {
        Toast("请先登录");
        this.$router.push("./login");
      } else {
        try {
          const col = await CollegeCollect(formData);
          console.log(col);
        } catch (e) {
          console.log(e);
        }
        this.showSearch = !this.showSearch;
      }
    },

    // 信息反馈
    backClick() {
      if (!window.localStorage.getItem("token")) {
        Toast("请先登录");
        this.$router.push("./login");
      } else {
        this.feedshow = true;
      }
    },
    feedcancel() {
      this.feedshow = false;
    },
    async Submit() {
      var Data = new FormData();
      Data.append("content", this.feedData);
      console.log(Data);

      const res = await Collegefeedback(Data);
      if (res.data.code == 200) {
        Toast(res.data.msg);
        this.feedshow = false;
      } else {
        Toast(res.data.msg);
      }
      console.log(res);
      // console.log(this.feedData)
    },

    //---加群复制
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        console.log(e);
        Toast("复制成功，请到微信添加老师");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e);
        // 不支持复制
        Toast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    
    // 项目简介跳转
    Jane(){
      this.$router.push({
        path: "/introduce",
        query: {
          id: this.id,
        },
      });
    }
  },
};
</script>
<style scoped>
.header {
  padding: 0.5rem 0.3rem;
  background-image: linear-gradient(-90deg, #043c6a 0%, #43c6ac 100%);
  height: 3rem;
}
.header h2 {
  color: #ffffff;
  font-size: 0.34rem;
  letter-spacing: 0.01rem;
  width: 6.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.school {
  margin-top: 0.4rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.school_l {
  display: flex;
  align-items: center;
}
.logo img {
  height: 1.1rem;
  background: #fff;
  border-radius: 50%;
}
.labelTop,
.labelFot {
  display: flex;
  margin-left: 0.05rem;
}
.labelTop span {
  display: block;
  background: #ffffff;
  width: 1.05rem;
  font-size: 0.2rem;
  color: #007751;
  /* padding: .07rem 0; */
  text-align: center;
  margin-left: 0.1rem;
  border-radius: 0.3rem;
  border: 1px solid #ffffff;
  height: 0.4rem;
  line-height: 0.4rem;
}
.labelFot span {
  display: block;
  border: 1px solid #ffffff;
  width: 1.05rem;
  font-size: 0.2rem;
  color: #ffffff;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  margin-left: 0.1rem;
  border-radius: 0.3rem;
  margin-top: 0.2rem;
}
.address img {
  height: 0.25rem;
}
.address span {
  display: block;
  margin-left: 0.08rem;
}
.address {
  font-size: 0.2rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  /* margin-right: 0.3rem; */
  margin-top: 0.5rem;
}

/* 详情 */
.xiangqng {
  background: #ffffff;
  margin-top: -0.9rem;
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  min-height: 5rem;
  overflow: hidden;
}
.other {
  display: flex;
  margin: 0.5rem 0.3rem 0.3rem;
  background: #f6f6f6;
  height: 1rem;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.15rem;
}
.other span:nth-child(1) {
  color: #333333;
  font-size: 0.24rem;
  display: block;
  margin-left: -0.15rem;
}
.other span:nth-child(2) {
  width: 1.5rem;
  font-size: 0.24rem;
  color: #999999;
  display: block;
  line-height: 0.3rem;
  font-family: SimSun;
}
.whole {
  margin: 0.3rem;
  /* border: 1px solid firebrick; */
}
/* 基本信息 */
.biaoti {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0.35rem 0;
  justify-content: space-between;
}
.biaoti_l,
.biaoti_r {
  display: flex;
  align-items: center;
  margin-right: 0.15rem;
}
.biaoti .hr {
  display: block;
  width: 0.1rem;
  height: 0.4rem;
  background: #28e6c0;
  border-radius: 0.15rem;
}
.biaoti .xinxi {
  color: #333333;
  font-size: 0.3rem;
  display: block;
  margin-left: 0.2rem;
  letter-spacing: 0.02rem;
}
.biaoti_r p {
  display: flex;
  font-size: 0.24rem;
  color: #666666;
  font-family: PingFang-SC-Regular;
  margin-left: 0.4rem;
}
.biaoti_r p img {
  height: 0.25rem;
}
.biaoti_r p span {
  display: block;
  margin-left: 0.1rem;
}
.basic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.basic_l {
  width: 3.52rem;
  height: 2.15rem;
}
.basic_l img {
  width: 3.52rem;
  height: 2.15rem;
}
.basic_r {
  width: 3rem;
  height: 2.15rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.24rem;
}
.basic_r h2 {
  color: #333333;
  padding: 0.2rem 0;
}
.basic_r p {
  color: #999999;
  line-height: 0.35rem;
  width: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /*超出3行部分显示省略号，去掉该属性 显示全部*/
  -webkit-box-orient: vertical;
}
.basic_r p span {
  padding-left: 0.2rem;
}

.information {
  margin: 0.35rem 0 0.4rem 0;
}
.information td {
  word-break: break-all;
  word-wrap: break-word;
  display: table-cell;
  vertical-align: middle;
}
.infor_l {
  width: 1.63rem;
  padding: 0.2rem 0;
  background-color: #f6f6f6;
  font-family: PingFang-SC-Regular;
  font-size: 0.28rem;
  color: #333333;
  box-sizing: border-box;
  padding-left: 0.3rem;
  border-bottom: 3px solid #ffffff;
}
.infor_r {
  width: 5.16rem;
  padding: 0.12rem 0;
  background-color: #f3f4f8;
  border-left: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  box-sizing: border-box;
  padding-left: 0.3rem;
  color: #666666;
  font-size: 0.28rem;
  line-height: 0.35rem;
}

/* 分数线 */
.scoreTitle {
  width: 1.46rem;
  height: 0.35rem;
  border: solid 1px #007751;
  color: #007751;
  font-size: 0.2rem;
  position: absolute;
  z-index: 99999;
  top: -20px;
}
.nav {
  position: relative;
}

/* 招生信息 */
.recruit {
  margin: 0.3rem;
}
.infor_r a {
  color: #999999;
  border-bottom: 1px solid #999999;
}
.infor_r a:nth-child(2) {
  margin-left: 0.3rem;
}
.infor_r a:nth-child(3) {
  margin-left: 0.3rem;
}
.infor_r a:nth-child(4) {
  margin-left: 0.3rem;
}
.infor_r span:nth-child(2) {
  margin-left: 0.2rem;
}
.infor_r span:nth-child(3) {
  margin-left: 0.2rem;
}

/* 关注公众号 */
.follow {
  border-top: 1px solid #f0eded;
  margin: 0.8rem 0.3rem 0.3rem 0.3rem;
}
.followBor {
  display: flex;
  padding: 0.4rem 0;
  align-items: center;
  justify-content: space-between;
}
.follow_l {
  display: flex;
  align-items: center;
}
.follow_l p {
  margin-left: 0.35rem;
}
.followBor img {
  height: 1.1rem;
}
.followTitle span:nth-child(1) {
  display: block;
  font-family: PingFang-SC-Regular;
  font-size: 0.29rem;
  color: #333333;
  margin-bottom: 0.15rem;
}
.followTitle span:nth-child(2) {
  font-size: 0.24rem;
  color: #999999;
  font-family: PingFang-SC-Light;
  line-height: 0.35rem;
}
.btn {
  display: block;
  width: 1.6rem;
  height: 0.67rem;
  background-color: #baf4e8;
  border-radius: 0.5rem;
  line-height: 0.67rem;
  color: #000;
  text-align: center;
}

.popup {
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 0.5rem 0.5rem 0 0;
}
.popupcon {
  text-align: center;
}
.popupcon span {
  display: block;
  line-height: 0.55rem;
  font-size: 0.24rem;
  color: #000000;
}
.popupcon p {
  margin: 0.2rem 0;
  padding-top: 0.3rem;
  border-top: 10px solid #f6f6f6;
  color: #666666;
  font-size: 0.24rem;
}

/* 对比 */
.duibiBor {
  border-radius: 0 0 0.3rem 0.3rem;
}
.duibi {
  margin-bottom: 0.55rem;
}
.contrast {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.3rem 0 0.6rem;
  background: #d4d4d4;
}
.contrastBor {
  height: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contrastBor > span {
  display: block;
  margin-top: 0.1rem;
}
.contrast_l {
  display: flex;
  color: #fffefe;
  font-size: 0.3rem;
  font-family: PingFang-SC-Regular;
  align-items: center;
}
.contrast_l img {
  height: 0.35rem;
}
.contrast_l span {
  display: block;
}
.contrast_l span:nth-child(2) {
  padding-left: 0.2rem;
}
.yuanxiao {
  display: flex;
  margin: 0 0.3rem 0 0.6rem;
  height: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  color: #666666;
  font-size: 0.3rem;
}
.duibiBtn {
  display: flex;
  margin-top: 0.45rem;
  justify-content: center;
}
.duibiBtn button:nth-child(1) {
  width: 1.8rem;
  height: 0.68rem;
  border-radius: 0.12rem;
  border: solid 1px #d4d4d4;
  background: none;
  color: #666666;
  font-family: PingFang-SC-Regular;
  font-size: 0.3rem;
}
.duibiBtn button:nth-child(2) {
  width: 1.8rem;
  height: 0.67rem;
  border-radius: 0.12rem;
  background-color: #007751;
  margin-left: 0.27rem;
  border: none;
  font-family: PingFang-SC-Regular;
  font-size: 0.3rem;
  color: #ffffff;
}

/* 右侧咨询 */
.inquiry {
  position: fixed;
  right: 0.4rem;
  bottom: 1.6rem;
}
.inquiry img {
  height: 1.2rem;
}

.tab-tilte {
  display: flex;
  justify-content: space-around;
  color: #999999;
}
.tab-tilte li {
  /* margin: 0 0.3rem; */
  padding: 0.2rem 0 0.1rem 0;
  font-size: 0.28rem;
}
.active {
  /* border-bottom: 2px solid #188ded; */
  font-weight: bold;
  font-size: 0.3rem;
  color: #333333;
}
.cur {
  font-size: 0.28rem;
  color: #999999;
}
.tab-tilte1 {
  display: flex;
  justify-content: space-around;
}
.active1 {
  height: 0.1rem;
  width: 0.29rem;
  background-color: #28e6c0;
  box-sizing: border-box;
  border-radius: 0.1rem;
}
.active1:nth-child(1) {
  margin-left: -0.2rem;
}
.echarseTitle {
  width: 2rem;
  height: 0.34rem;
  border-radius: 2px;
  border: solid 1px #007751;
  line-height: 0.34rem;
  text-align: center;
  font-size: 0.2rem;
  color: #007751;
  margin-left: 0.45rem;
  margin-top: 0.2rem;
  margin-bottom: 0.25rem;
}

/* 视频 */
.video {
  width: 6.5rem;
  height: 5rem;
}

.feedback {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.feedback p {
  font-size: 0.24rem;
  color: #666666;
  line-height: 0.35rem;
}
.feedback p span {
  font-family: SimSun;
  color: #2672ab;
}
.feedpopup {
  width: 6.5rem;
  margin: 0 auto;
  /* height: 6rem; */
  border-radius: 0.1rem;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem 0;
}
.feedpopup p {
  text-align: center;
  font-size: 0.3rem;
  font-weight: bold;
}
.feedpopup span {
  display: block;
  padding: 0.25rem 0;
  color: #666666;
  font-size: 0.24rem;
}
.feedpopup textarea {
  width: 5.7rem;
  height: 3rem;
  border: none;
  background: #f9f7f8;
  border-radius: 0.1rem;
  font-size: 0.28rem;
  color: #666666;
  box-sizing: border-box;
  padding: 0.15rem;
}
.fankuiBtn {
  display: flex;
  border-top: 1px solid #f9f7f8;
  margin-top: 0.2rem;
}
.fankuiBtn button {
  width: 50%;
  border: none;
  background: none;
  height: 1rem;
  font-size: 0.28rem;
}
.fankuiBtn button:nth-child(1) {
  border-right: 1px solid #f9f7f8;
  color: #999999;
}
.fankuiBtn button:nth-child(2) {
  color: #2672ab;
}
</style>
<template>
  <div class="add">
    <router-view></router-view>
    <div class="footer">
      <van-tabbar v-model="active">
        <van-tabbar-item
          :name="item.path"
          v-for="(item, index) in icon"
          :key="index"
          @click="clickFoo(index)"
          :id="index"
        >
          <span>{{ item.name }}</span>
          <template #icon="props">
            <img :src="props.active ? item.active : item.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: this.$route.path,
      icon: [
        {
          active:
            "http://www.xiongsongedu.com/uploads/20210603/f85598487364c24fe5b9f87055371fb9.png",
          inactive:
            "http://www.xiongsongedu.com/uploads/20210603/9e2cc63df889b6f7324ccd454507568f.png",
          name: "院校",
          path: "/",
        },
        // {
        //     active: 'http://www.xiongsongedu.com/uploads/20210603/ed9fbf85ad4be5a39bdec7039cd07f70.png',
        //     inactive: 'http://www.xiongsongedu.com/uploads/20210603/9279294cf340c07652d3fead10f0dde3.png',
        //     name:'圈子',
        //     path:''
        // },
        {
          active:
            "http://www.xiongsongedu.com/uploads/20210603/48d307ec362b7b734995738defe88beb.png",
          inactive:
            "http://www.xiongsongedu.com/uploads/20210603/aa6e753c7d768fa579e720c09429eef9.png",
          name: "我的",
          path: "/my",
        },
      ],
    };
  },
  methods: {
    clickFoo(index) {
      if (index == 0) {
        this.$router.push("/");
      }
        // else if(index == 1){
        //      this.$router.push('/xuexizhongx')
        // }
      else if (index == 1) {
        this.$router.push("/my");
      }
    },
  },
};
</script>

<style scoped>
.footer .van-tabbar-item--active {
  color: #28e6c0;
  font-size: 0.24rem;
  margin-top: .1rem;
}
.footer .van-tabbar-item__icon img {
  height: 27px;
}
.footer .van-tabbar-item {
  font-size: 0.24rem;
}
.footer .van-tabbar {
  height: 60px;
}
.add {
  max-width: 7.5rem;
  margin: 0 auto;
}
</style>
<template>
  <div class="jianjie">
    <div class="conter">
      <h2>{{ conter.college_name }}{{ conter.major_type }}项目</h2>
      <p v-html="basicInfo.description">{{ basicInfo.description }}</p>
    </div>
  </div>
</template>
<script>
import { CollegeDetail } from "../api/home";
export default {
  data() {
    return {
      id: this.$route.query.id,
      conter: [],
      basicInfo: [],
    };
  },
  async mounted() {
    try {
      const res = await CollegeDetail({
        id: this.id,
      });
      this.conter = res.data.data;
      this.basicInfo = this.conter.basicInfo;
      console.log(res.data.data);
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped>
.jianjie {
  min-height: 90vh;
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 0.5rem 0.3rem;
  margin: 0.5rem 0.3rem;
  border-radius: 0.25rem;
}
.conter h2 {
  font-size: 0.3rem;
  letter-spacing: 0.01rem;
  color: #333333;
  font-family: PingFang-SC-Regular;
  font-weight: bold;
  font-stretch: normal;
  padding-bottom: .15rem;
  border-bottom: 1px solid #999999;
}
.conter p{
  padding-top: .4rem;
  font-size: .25rem;
  line-height: .3rem;
  font-family: PingFang-SC-Regular;
  color: #999999;
  line-height: .45rem;
}
</style>
<template>
  <div>
    <table>
      <thead>
        <tr class="trTitle">
          <th>院校名称</th>
          <th v-for="(list, index) in result" :key="index">
            <span class="thSpan1">{{ list.college_name }}</span>
            <p @click="colse(list.id, index)">
              <span>关闭</span>
              <span><van-icon name="cross" /></span>
            </p>
          </th>

          <!-- <th>
               <span class="thSpan1"> 北京大学汇丰商学院</span>
                <p>
                <span>关闭</span>
                <span><van-icon name="cross" /></span>
              </p>
             </th> -->
          <th v-if="length != 4">
            <div class="add" @click="add">
              <span><van-icon name="plus" /></span>
              <span>添加</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="trStyle">
          <td>所在地</td>
          <td v-for="(list, a) in result" :key="a">{{ list.city_name }}</td>
        </tr>
        <tr class="trStyle tdBack">
          <td>院校性质</td>
          <td v-for="(list, b) in result" :key="b">{{ list.college_types }}</td>
        </tr>
        <tr class="trStyle">
          <td>国际认证</td>
          <td v-for="(list, c) in result" :key="c">{{ list.cert_types }}</td>
        </tr>
        <tr class="trStyle tdBack">
          <td>项目</td>
          <td v-for="(list, d) in result" :key="d">{{ list.major_type }}</td>
        </tr>
        <tr class="trStyle">
          <td>学费</td>
          <td v-for="(list, e) in result" :key="e" v-html="list.tuition">
            {{ list.tuition }}
          </td>
        </tr>
        <tr class="trStyle tdBack">
          <td>学制</td>
          <td v-for="(list, f) in result" :key="f">{{ list.edu_system }}</td>
        </tr>
        <tr class="trStyle tdBot">
          <td>招生人数</td>
          <td v-for="(list, g) in result" :key="g">{{ list.enroll_number }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { CollegeContrast } from "../api/home";
export default {
  data() {
    return {
      colleges: [],
      id: [],
      result: [],
      length: "",
    };
  },
  async mounted() {
    this.colleges = JSON.parse(localStorage.getItem("school"));
    // console.log(this.colleges)
    var a = [];
    this.colleges.forEach((item) => {
      a.push(item.id);
      this.id = a.toString();
    });
    try {
      const res = await CollegeContrast({
        ids: this.id,
      });
      this.result = res.data.data;
      this.length = res.data.data.length;
      // console.log();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    add() {
      this.$router.push("/");
    },
    async colse(id) {
      // ---删除本地存储
      var n = JSON.parse(localStorage.getItem("school"));
      for (var i = 0; i < n.length; i++) {
        if (n[i].id == id) {
          n.splice(i, 1);
        }
      }
      var schools = JSON.stringify(n);
      localStorage.setItem("school", schools);
      var data = n;
      console.log(data);

      var d = [];
      data.forEach((item) => {
        console.log(item.id);
        d.push(item.id);
        this.id = d.toString();
      });
      try {
        const res = await CollegeContrast({
          ids: this.id,
        });
        this.result = res.data.data;
        this.length = res.data.data.length;
        console.log(res.data.data);
      } catch (e) {
        console.log(e);
      }
      console.log(n);
    },
  },
};
</script>
<style>
table {
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
}
</style>
<style scoped>
th {
  padding: 0.1rem 0.05rem;
}
.trTitle th {
  position: relative;
  height: 1.7rem;
  line-height: 0.33rem;
  border-right: 1px solid #c7c7c7;
  background: #d4d4d4;
}
.trTitle {
  font-size: 0.28rem;
  padding: 0.4rem;
  box-sizing: border-box;
}
.trTitle th:nth-child(1) {
  width: 0.8rem;
  font-weight: bold;
  color: #333333;
}
.trTitle th:nth-child(2) {
  width: 1.8rem;
}
.trTitle th:nth-child(3) {
  width: 1.8rem;
}
.trTitle th:nth-child(4) {
  width: 1.8rem;
}
.trTitle th:nth-child(5) {
  width: 1.8rem;
  /* vertical-align: middle; */
  border-right: none;
}
.trTitle p {
  display: flex;
  align-items: center;
  width: 1.11rem;
  padding: 0.05rem;
  background-color: #d4d4d4;
  border-radius: 0.06rem;
  border: solid 1px #6e6e6e;
  justify-content: space-around;
  bottom: 0.2rem;
  position: absolute;
  left: 0.2rem;
}
.trTitle p span {
  display: block;
  text-align: center;
}
.add span {
  display: block;
}
.thSpan1 {
  color: #000;
}
.trStyle {
  padding: 0.4rem;
  box-sizing: border-box;
}
.trStyle td:nth-child(1) {
  font-weight: bold;
  color: #333333;
}
.trStyle td:nth-child(5) {
  border-right: none;
}
.trStyle td {
  border-right: 1px solid #c7c7c7;
  line-height: 0.33rem;
  padding: 0.2rem 0.1rem;
  font-size: 0.28rem;
  color: #999999;
}
.tdBack {
  background: #f3f3f3;
}
.tdBot {
  border-bottom: 1px solid #c7c7c7;
}
</style>
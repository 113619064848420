import Vue from "vue";
import VueRouter from "vue-router";

import index from "../views/index.vue"
import detia from "../views/detia.vue"
import Footer from "../views/footer"
import my from "../views/my"
import news from "../views/news"
import contrast from "../views/contrast"
import login from "../views/login"
import introduce from "../views/introduce"


/* 使用vue-router */
Vue.use(VueRouter);

const routes = [{
		path: "/",
	component: Footer,
	children:[
		{
			path: "/",
			component: index,
			meta: {
				title: '院校库'
			}
		},
		{
			path: "/detia",
			component: detia,
			meta: {
				title: '院校详情'
			}
		},
		{
			path: "/my",
			component: my,
			meta: {
				title: '个人中心'
			}
		},
	]
	},
	
	{
		path: "/news",
		component: news,
		meta: {
			title: '消息中心'
		}
	},
	{
		path: "/contrast",
		component: contrast,
		meta: {
			title: '院校对比'
		}
	},
	{
		path: "/login",
		component: login,
		meta: {
			title: '登录'
		}
	},
	{
		path: "/introduce",
		component: introduce,
		meta: {
			title: '项目简介'
		}
	},
	

	
]


var router = new VueRouter({
	routes,
	mode: 'history',
	// base: "/yuanxiaoku",
	scrollBehavior(){
		return {y:0}
	}
})
export default router;

import { request } from './index';
import { rquestAuth } from './index';
function exitToken() {
  const token = window.localStorage.getItem('token');
  return token ? { token } : {}
}

export function CollegeList(params) {
  return request.get('/college/list', {
    params
  })
}
export function CollegeDetail(params) {
  return request.get('/college/detail', {
    params,
    headers: {
      ...exitToken()
    }
  })
}

// ---对比
export function CollegeContrast(params) {
  return rquestAuth.get('/college/contrast', {
    params
  })
}

// ---收藏
export function CollegeCollect(data) {
  return rquestAuth.post('/user/college/collect', data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

// ---我的
export function UserCenter() {
  return rquestAuth.get('/user/center')
}

// ---查看附近院校
export function CollegesameArea(params) {
  return rquestAuth.get('/college/sameArea', {
    params
  })
}

// ---信息反馈
export function Collegefeedback(data) {
  return rquestAuth.post('/user/feedback', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}
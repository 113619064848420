import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/router.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { rem } from './until/rem.js'
import VCalendar from 'v-calendar'; // 引入日历插件

Vue.use(VCalendar, {
	componentPrefix: 'vc',
});

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

rem(750, 750);
Vue.use(Vant);
Vue.use(ElementUI);

import { Lazyload } from 'vant';

Vue.use(Lazyload);

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(VueRouter);	//全局使用vuerouter

new Vue({
	el: '#app',
	router,
	render: h => h(App),
}).$mount('#app')

import axios from "axios"
import { Toast } from 'vant'

export const request = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : 'https://go.xiongsongedu.com/library'
})

export const rquestAuth = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : 'https://go.xiongsongedu.com/library'
  // url:''
});


function reqInterceptor(config) {
  return config
}

function resInterceptor(res) {
  //  console.log(res)
  if (res.data.code == 1) {
    return res
  }

  if (res.data.code == -2 || res.data.code == 401) {
    // 提示
    Toast("登录已过期")
    window.localStorage.removeItem('token');
    window.location.href = ""
    
  }
  if (res.data.code == 0) {
    throw res
  }
  return res
}


rquestAuth.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers,
    token: window.localStorage.getItem('token')
  }
  return config
});

rquestAuth.interceptors.response.use(resInterceptor);
request.interceptors.request.use(reqInterceptor);
request.interceptors.response.use(resInterceptor);

<template>
  <div class="sign" id="app">
    <!-- <link href="/mobile/css/sharecourse.css" rel="stylesheet" /> -->
    <h1 style="margin-top:70px">登录/注册</h1>
    <h2>未注册的手机号验证后将自动注册</h2>
    <div class="field-form">
      <div class="field">
        <span>+86</span>
        <input v-model="mobile" class="mobile" placeholder="手机号" />
      </div>
      <div class="forgetbox">
        <div class="field">
          <span
            ><img src="http://www.xiongsongedu.com/mobile/image/09617addb63d815de3b67c039ebbd843.png"
          /></span>
          <input v-model="verificationCode" class="code" placeholder="验证码" />
        </div>
        <button class="get-code" v-on:click="fetchVerficationCode">{{textContent}}</button>
      </div>
    </div>
    <button class="submit login-code" v-on:click="onSign">登陆/注册</button>
  </div>
</template>
<script>
import { Toast } from 'vant';
import  axios from 'axios';
import cookies from 'js-cookie'
const remenber = (call) => {
  var count = 60;
  var s = null;
  return (s = setInterval(() => {
    --count;
    if (count === 0) {
      count = 60;
      clearInterval(s);
      call(false);
      return;
    }
    call(count);
  }, 1000));
};

function verifyPhone(phone) {
  return /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/.test(
    phone
  );
}

export default {
    data() {
        return {
            mobile:"",
            verificationCode:"",
            lock:false,
            textContent:'获取验证码',
            NqQ3uWNa:"NqQ3uWNa"
        }
    },
    methods:{
        // 获取验证码处理器
        fetchVerficationCode() {
            if (this.lock) return;
            if (!verifyPhone(this.mobile)){
                Toast('手机号输入错误')
                return
            }
            this.lock = true;
            // 倒计时
            remenber(count => {
                if (count === false) {
                    this.lock = false
                    this.textContent = '重新获取'
                } else {
                    this.textContent = count + '秒'
                }
            });
            // 获取验证码
            axios.get('https://www.xiongsongedu.com/api/sms/send?type=3&mobile=' + this.mobile).then((res)=>{
                Toast(res.data.msg);
            });
        },
        // 登录处理器
        onSign() {
            if (this.verificationCode === "") {
                Toast('验证码不能为空');
                return;
            }
            const query = new URLSearchParams(window.location.search);
            console.log(decodeURIComponent(query.get('redirect')))
            axios.post('https://www.xiongsongedu.com/api/user/login',{
                mobile: this.mobile,
                code: this.verificationCode,
                qr_receive : this.NqQ3uWNa,
                source_id:45
            }).then(res=> {
                if (res.data.code !== 1) {
                    Toast(res.data.msg);
                    return;
                }
                if(res.data.code == 1 ){
                    Toast(res.data.msg)
                     localStorage.setItem("token", res.data.data.token);
                    cookies.set('token',res.data.data.token, {path: '/',domain: '.xiongsongedu.com'})
                    this.$router.go(-1)
                       return;
                }
                // Toast({
                //     message:res.data.msg,
                //     onClose(){
                //         // 跳转重定向
                //          if (redirect) {
                //              // 区分URL是否带查询
                //              if (redirect.match(/\?.+/)) {
                //                 window.location.href = redirect + '&token=' + res.data.data.token;
                //              } else {   
                //                  window.location.href = redirect + '&token=' + res.data.data.token;
                //              }
                //          }
                //     }
                // })
               
            })

        }
    }
};
</script>
<style scoped>
  .sign{
    width: 6.5rem;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .sign>h1 {
    font-size: .4rem;
    font-weight: 500;
  }
  .sign>h2 {
    font-size: .25rem;
    color: #818181;
    margin-top: .2rem;
  }
  .sign .field-form {
    margin-top: .5rem;
  }
  .sign .field {
    border-bottom: 1px solid #818181;
    color: #818181;
    padding-bottom: .2rem;
}
.sign .field span {
    font-size: 4.8vw;
    font-weight: bold;
}
.sign .field input {
    background: none;
    outline: none;
    border: none;
    padding-left: 2.66667vw;
    font-size: 4.26667vw;
}
.sign .forgetbox {
    margin-top: 6.4vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.sign .forgetbox input {
    width: 33.33333vw;
}
.sign .forgetbox img {
    width: 3.73333vw;
    height: 5.06667vw;
    vertical-align: middle;
    padding-left: 4vw;
    padding-right: 1.33333vw;
}
.sign .forgetbox button {
    border: solid 1px #b3b3b3;
    border-radius: 4vw;
    margin: 0;
    padding: 0;
    outline: none;
    width: 26vw;
    height: 8.26667vw;
    background: transparent;
    color: #818181;
    font-size: 3.73333vw;
}

.sign .submit {
    width: 81.6vw;
    height: 12vw;
    background-image: linear-gradient(
90deg
, #ffef98 0%, #ffae64 100%),linear-gradient(#ffaf64, #ffae64);
    background-blend-mode: normal, normal;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    outline: none;
    font-size: 4.8vw;
    color: white;
    border-radius: 6.66667vw;
    margin-top: 8.93333vw;
}
</style>




<template>
  <div class="myBody">
    <!-- 考研时间 -->
    <div class="study">
      <div class="study_l">
        <p>考研时间</p>
        <p>
          <span>2021</span>
          <i>年</i>
          <span>12</span>
          <i>月</i>
          <span>25</span>
          <i>日</i>
        </p>
      </div>
      <div class="study_r">
        <p>倒计时</p>
        <span>{{ time }}天</span>
      </div>
    </div>
    <!-- 个人信息 -->
    <div class="personal">
      <div class="personal_l" v-if="!token">
        <span>
          <img
            src="http://www.xiongsongedu.com/uploads/20210622/99a6898a19f38f92663db86338a4ab3c.png"
            alt=""
          />
        </span>
        <span @click="login">注册/登录</span>
      </div>
      <div class="personal_l" v-else>
        <span v-if="infor.imgurl_wx == null">
          <img
            src="http://www.xiongsongedu.com/uploads/20210622/99a6898a19f38f92663db86338a4ab3c.png"
            alt=""
          />
        </span>
        <span v-else>
          <img :src="infor.imgurl_wx" alt="" />
        </span>
        <span>HELLO，{{ infor.nickname }}</span>
      </div>
      <div class="personal_r" @click="news">
        <span v-if="!token">您有0条新消息</span>
        <span v-else>您有{{ count.message }}条新消息 ></span>
        <span>
          <img
            src="http://www.xiongsongedu.com/uploads/20210604/292a7f39a8c78477bd571e1dd8dab70a.png"
            alt=""
          />
        </span>
      </div>
    </div>
    <!-- 报告 -->
    <div class="report">
      <div class="reportTop">
        <p>
          <span v-if="!token">0</span>
          <span>{{ count.collect }}</span>
        </p>
        <p>
          <span v-if="!token">0</span>
          <span>{{ count.report }}</span>
        </p>
        <p>
          <span v-if="!token">0</span>
          <span>{{ count.hub }}</span>
        </p>
      </div>
      <div class="reportfot">
        <span>我的收藏</span>
        <span>我的报告</span>
        <span>关注圈子</span>
      </div>
    </div>
    <!-- 考研神器 -->
    <div class="myTitle">
      <span>考研神器</span>
    </div>
    <div class="Artifact">
      <p
        class="Artifact_P"
        v-for="(list, index) in Artifact"
        :key="index"
        @click="artwchat(index)"
      >
        <span><img :src="list.picture" alt="" /></span>
        <span>{{ list.name }}</span>
      </p>
    </div>
    <van-popup v-model="show">
      <div class="program">
        <img :src="wchat" alt="" />
        <span>长按识别进入小程序</span>
      </div>
    </van-popup>
    <!-- 学习工具 -->
    <div class="myTitle">
      <span>学习工具</span>
    </div>
    <div class="Artifact">
      <p class="Artifact_P" v-for="(list, index) in tool" :key="index" @click="toolStu(index)">
        <span><img :src="list.picture" alt="" /></span>
        <span>{{ list.name }}</span>
      </p>
    </div>
    <van-popup v-model="toolShow">
      <div class="program">
        <img :src="toolImg" alt="" />
        <span>长按识别进入小程序</span>
      </div>
    </van-popup>
    <!-- 推荐关注 -->
    <div class="myTitle">
      <span>推荐关注</span>
    </div>
    <div class="Artifact">
      <p class="Artifact_P" v-for="(list, index) in follow" :key="index">
        <span><img :src="list.picture" alt="" /></span>
        <span>{{ list.name }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { UserCenter } from "../api/home";
export default {
  data() {
    return {
      Artifact: [
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/44278536e947eb045adee6547149cc56.png",
           name: "资料库",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/c798f44ca5e1f3c6b7a9aa7fcbed69cf.png",
          name: "面试测评",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/cae8f7230506608d9b1424243a4ff9b6.png",
          name: "在线估分",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/3112aa0d5f89b59d9475cb01256e0256.png",
          name: "调剂系统",
        },
      ],
      tool: [
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/51d7e9866bcf924bb670e9c751c5b2c7.png",
          name: "背单词",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/27ca05acd612259c0d6aadd6893a3f62.png",
          name: "刷阅读",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/0c45caa4054fb71cb5d7e96f57a9c434.png",
          name: "记公式",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/dea9a64ce3db2ef9254a3e67e5afe730.png",
          name: "数逻刷题",
        },
      ],
      follow: [
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/10b16568607b0e6ca7f6595d862b4d49.png",
          name: "小白入门",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/b4e9a036ee6348cddcd3ad08cdb8de95.png",
          name: "考研日历",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/929fdb8b20afe00aad7cc09aab9b2280.png",
          name: "分数线",
        },
        {
          picture:
            "http://www.xiongsongedu.com/uploads/20210604/e003cc8a3d7a15774d8731426a6a5662.png",
          name: "成绩查询",
        },
      ],
      show: false,
      toolShow:false,
      wchat:"",
      toolImg:"",
      infor: [],
      count: [],
      time: "",
      token: window.localStorage.getItem("token"),
    };
  },
  methods: {
    artwchat(index) {
      this.show = true;
      if (index == 0) {
        this.wchat =  "http://www.xiongsongedu.com/uploads/20210702/dbe7855a4233d6ca1c2b7b3ecfd819de.jpg";
      } else if (index == 1) {
        this.wchat =  "http://www.xiongsongedu.com/uploads/20210702/65d08a41719cbaa354b632c8ebbbc8b8.jpg";
      }else if (index == 2) {
        this.wchat = "http://www.xiongsongedu.com/uploads/20210703/cd9f87c2520482bbbc6058112345ddc1.jpg";
      }else if (index == 3) {
        this.wchat ="http://www.xiongsongedu.com/uploads/20210702/0c49ac4020659899275041afa4c7a176.jpg";
      }
    },
    toolStu(index){
      this.toolShow = true;
       if (index == 0) {
         this.toolImg ="http://www.xiongsongedu.com/uploads/20210702/d4b614eb5cd3a26421aaeef988d9f307.jpg"
       }else if (index == 1) {
          this.toolImg ="http://www.xiongsongedu.com/uploads/20210702/3f2d10fd6682fc5922247f34e1d4ffa4.jpg"
       }else if (index == 2) {
          this.toolImg ="http://www.xiongsongedu.com/uploads/20210702/e4fdd8f5f4e8789bd5fa06607d3eab8f.png"
       }else if (index == 3) {
          this.toolImg ="http://www.xiongsongedu.com/uploads/20210702/7f1e6ef1ba3107163dbd49877e09ffff.jpg"
       }
    },
    
    news() {
      this.$router.push("/news");
    },
    timeout() {
      var fromDay = Math.floor(
        (new Date("12/25/2021").getTime() - Date.now()) / (60 * 60 * 24 * 1000)
      );
      // console.log(fromDay)
      this.time = fromDay;
    },
    login() {
      this.$router.push("./login");
    },
  },
  async mounted() {
    console.log(window.localStorage.getItem("token"));
    if (window.localStorage.getItem("token") != null) {
      try {
        const res = await UserCenter();
        this.infor = res.data.data;
        this.count = this.infor.count;
        console.log(this.infor);
      } catch (e) {
        console.log(e);
      }
    }
    this.timeout();
  },
};
</script>
<style scoped>
/* 考研时间 */
.myBody {
  margin: 0.25rem 0.3rem 0.4rem 0.4rem;
  font-family: PingFang-SC-Regular;
  /* border: 1px solid firebrick; */
}
.study {
  display: flex;
  justify-content: space-between;
}
.study_l {
  display: flex;
  font-family: PingFang-SC-Medium;
  align-items: center;
}
.study_l p:nth-child(1) {
  font-family: PingFang-SC-Medium;
  font-size: 0.24rem;
  color: #333333;
}
.study_l p:nth-child(2) {
  display: flex;
  align-items: center;
  margin-left: 0.08rem;
}
.study_l span {
  display: block;
  font-size: 0.24rem;
  background-color: #28e6c0;
  color: #ffffff;
  padding: 0 0.15rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 0.2rem;
  margin-left: 0.05rem;
}
.study_l i {
  color: #28e6c0;
  font-size: 0.24rem;
  margin-left: 0.05rem;
}
.study_r {
  display: flex;
  font-family: PingFang-SC-Medium;
  font-size: 0.24rem;
  margin-right: 0.15rem;
}
.study_r p {
  color: #333333;
}
.study_r span {
  color: #999999;
  display: block;
  margin-left: 0.05rem;
}
/* 个人信息 */
.personal {
  width: 6.7rem;
  height: 1.06rem;
  background-color: #f6f6f6;
  border-radius: 0.19rem;
  margin: 0.25rem 0;
  box-sizing: border-box;
  padding: 0.15rem 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.personal_l,
.personal_r {
  display: flex;
  align-items: center;
}
.personal_l span:nth-child(1) {
  width: 0.74rem;
  height: 0.74rem;
  border-radius: 50%;
}
.personal_l img {
  width: 100%;
  border-radius: 50%;
}
.personal_l span:nth-child(2) {
  color: #333333;
  font-size: 0.24rem;
  display: block;
  margin-left: 0.15rem;
}
.personal_r span:nth-child(1) {
  color: #999999;
  font-family: SimSun;
  font-size: 0.24rem;
  display: block;
  margin-right: 0.1rem;
}
.personal_r span:nth-child(2) {
  display: block;
}
.personal_r img {
  width: 0.3rem;
}

/* 报告 */
.report {
  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
}
.reportTop,
.reportfot {
  display: flex;
  justify-content: space-around;
}
.reportTop span {
  display: block;
  font-size: 0.5rem;
  font-family: PingFang-SC-Medium;
  color: #28e6c0;
}
.reportfot span {
  display: block;
  color: #999999;
  font-size: 0.22rem;
  margin-top: 0.2rem;
}

/* 考研神器 */
.myTitle span {
  color: #333333;
  font-family: PingFang-SC-Medium;
  font-size: 0.3rem;
  display: block;
  margin-bottom: 0.35rem;
  padding-left: 0.1rem;
}
.Artifact {
  width: 6.7rem;
  height: 1.65rem;
  box-shadow: 0px 0.03rem 0.21rem 0px rgba(3, 0, 0, 0.05);
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0.28rem 0;
  margin-bottom: 0.5rem;
}
.Artifact_P {
  text-align: center;
}
.Artifact_P span:nth-child(1) {
  width: 0.74rem;
  height: 0.74rem;
  display: block;
  margin-bottom: 0.08rem;
}
.Artifact_P img {
  width: 100%;
}
.Artifact_P span:nth-child(2) {
  font-family: PingFang-SC-Regular;
  font-size: 0.22rem;
  color: #333333;
}
/* 弹出层 */
.program {
  width: 4rem;
  padding: 0.3rem 0.2rem;
}
.program img {
  width: 100%;
}
.program span {
  display: block;
  text-align: center;
  margin-top: 0.2rem;
  font-size: 0.28rem;
  color: #999999;
}
</style>
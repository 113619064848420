<template>
  <div>
    <!-- 搜索框 -->
    <div class="search" v-if="show">
      <input
        type="text"
        placeholder="请输入大学名称"
        ref="title"
        v-model="searchVal"
      />
      <van-icon
        name="http://www.xiongsongedu.com/uploads/20210528/32bd105165a1fe755538c0a35e544d87.png"
        size="0.4rem"
        color="#007751"
        @click="search()"
        :disabled="isDisable"
      />
    </div>
    <div class="chooice">
      <div class="chooiceLbor">
        <div class="address" @click="upchange" v-if="order">
          <span>学费</span>
          <span
            ><van-icon :name="xuefeiIcon"
              size="14"
          /></span>
        </div>
        <div class="address" @click="downchange" v-if="order1">
          <span>学费</span>
          <span
            ><van-icon
              name="http://www.xiongsongedu.com/uploads/20210707/70aa5ea8f1dd0b37e3b638e5781206ba.png"
              size="14"
          /></span>
        </div>
        <div class="zhuanye">
          <van-dropdown-menu>
            <van-dropdown-item v-model="value1" :options="option1"  @change="areaCheckedHandle" />
          </van-dropdown-menu>
        </div>
      </div>

      <!-- <div class="Chleft">
        <div class="address">
          <span>地区</span>
          <span
            ><van-icon
              name="http://www.xiongsongedu.com/uploads/20210603/84433fb40ac631a862d8ad48024540d4.png"
              size="10"
          /></span>
        </div>
        <div class="address">
          <span>专业</span>
          <span
            ><van-icon
              name="http://www.xiongsongedu.com/uploads/20210603/84433fb40ac631a862d8ad48024540d4.png"
              size="10"
          /></span>
        </div>
        <div class="address">
          <span>学费</span>
          <span
            ><van-icon
              name="http://www.xiongsongedu.com/uploads/20210603/7626f3c6c8173cbd7819125f8c9aa13b.png"
              size="14"
          /></span>
        </div>
      </div> -->

      <div class="chooice_r">
        <span @click="showInput">
          <van-icon
            name="http://www.xiongsongedu.com/uploads/20210528/01079928cd796b2ce13dc74fec67b80c.png"
            size=".3rem"
          />
        </span>
        <p @click="showPopup">
          <span>筛选</span>
          <span>
            <van-icon
              name="http://www.xiongsongedu.com/uploads/20210528/56bc894e0dd6a7b90802c02562e84013.png"
              size=".3rem"
          /></span>
        </p>
      </div>
    </div>
    <!-- 筛选 -->
    <van-popup
      v-model="Popup"
      class="Popup"
      position="bottom"
      :style="{ height: '92%' }"
      closeable
    >
      <div class="title"><span>全部筛选</span></div>
      <!-- 类别 -->
      <div class="category">
        <h2>专业类别</h2>
        <div class="btn">
          <el-checkbox-group v-model="checkboxGroup1" @change="handleCheck">
            <el-checkbox-button
              v-for="city in major"
              :label="city.name"
              :key="city.name"
              >{{ city.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>

      <!-- 院校性质 -->
      <div class="category">
        <h2>院校性质</h2>
        <div class="btn">
          <el-checkbox-group v-model="checkboxGroup2" @change="handleNature">
            <el-checkbox-button
              v-for="list in nature"
              :label="list.id"
              :key="list.name"
              >{{ list.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>

      <!-- 学习方式 -->
      <div class="category">
        <h2>学习方式</h2>
        <div class="btn">
          <el-checkbox-group v-model="checkboxGroup3" @click="handleMode">
            <el-checkbox-button
              v-for="list in mode"
              :label="list.id"
              :key="list.name"
              >{{ list.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>

      <!-- 提前面试 -->
      <div class="category">
        <h2>提前面试</h2>
        <div class="btn">
          <el-checkbox-group v-model="checkboxGroup4" @change="handleInterview">
            <el-checkbox-button
              v-for="list in interview"
              :label="list.id"
              :key="list.name"
              >{{ list.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>

      <!-- 调剂情况 -->
      <div class="category">
        <h2>调剂情况</h2>
        <div class="btn">
          <el-checkbox-group v-model="checkboxGroup5" @change="handleAdjust">
            <el-checkbox-button
              v-for="list in adjust"
              :label="list.id"
              :key="list.name"
              >{{ list.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>

      <!-- 地区 -->
      <div class="category">
        <h2>地区选择</h2>
        <div class="diqu">
          <el-form ref="form" class="sheng">
            <el-form-item label="省" class="sheng">
              <el-select
                v-model="province"
                placeholder="请选择"
                @change="selectChange"
              >
                <el-option
                  v-for="item in compony"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市/区" class="sheng">
              <el-select
                v-model="city"
                placeholder="请选择"
                multiple
                @change="cityChange"
              >
                <el-option
                  v-for="item in newCity"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="FOTBtn">
        <button @click="again">重置</button>
        <button @click="sure">完成</button>
      </div>
    </van-popup>

    <div class="colleges">
      <div
        @click="detia(list.id)"
        class="school"
        v-for="(list, index) in collegelist"
        :key="index"
      >
        <div class="collegesBor">
          <div class="colleges_logo">
            <img :src="list.school_badge_url" alt="" />
          </div>
          <div class="colleges_t">
            <h2>{{ list.college_name }}</h2>
            <span>{{ list.major_type }}项目</span>
          </div>
        </div>
        <div class="label">
          <div class="lable_bor">
            <p class="label_l">
              <span v-for="(item, index) in list.project_attr" :key="index">
                {{ item }}
              </span>
            </p>
            <p class="label_l">
              <span v-for="(item, index) in list.enroll_types" :key="index">{{
                item
              }}</span>
              <!-- <span v-for="(item1, id) in list.project_attr" :key="id">{{
              item1
            }}</span> -->
              <!-- <span v-if="!list.is_adjust == 0">{{
              list.is_adjust == 1 ? "可调剂" : ""
            }}</span> -->
              <span v-if="!list.is_interview == 0">{{
                list.is_interview === 1
                  ? "可提面"
                  : list.is_interview === 2
                  ? "必须提面"
                  : ""
              }}</span>
            </p>
          </div>

          <p class="label_r">
            <span
              ><van-icon
                name="http://www.xiongsongedu.com/uploads/20210603/5622d62661cd34db5ffd006338e3847d.png"
                size="15"
            /></span>
            <span>{{ list.province }}</span>
          </p>
        </div>
      </div>

      <div class="dixian">我是有底线的~</div>
    </div>
  </div>
</template>


<script>
// import { Toast } from "vant";
import { CollegeList } from "../api/home";
import areaList from "../api/area.json";
let ary = areaList;
export default {
  data() {
    return {
      value1: 0,
      xuefeiIcon:" http://www.xiongsongedu.com/uploads/20210603/7626f3c6c8173cbd7819125f8c9aa13b.png",
      option1: [
        { text: "MBA", value: 0 },
        { text: "EMBA", value: 1 },
        { text: "MPA", value: 2 },
        { text: "MPAcc", value: 3 },
        { text: "MTA", value: 4 },
        { text: "MLIS", value: 5 },
        { text: "MEM", value: 6 },
        { text: "MAud", value: 7 },
      ],
      show: false,
      Popup: false,
      checkboxGroup1: [],
      major: [
        {
          id: 1,
          name: "MBA",
        },
        {
          id: 2,
          name: "EMBA",
        },
        {
          id: 3,
          name: "MPA",
        },
        {
          id: 4,
          name: "MPAcc",
        },
        {
          id: 5,
          name: "MTA",
        },
        {
          id: 6,
          name: "MLIS",
        },
        {
          id: 7,
          name: "MEM",
        },
        {
          id: 8,
          name: "MAud",
        },
      ],
      checkboxGroup2: [],
      nature: [
        {
          id: 1,
          name: "985",
        },
        {
          id: 2,
          name: "211",
        },
        {
          id: 3,
          name: "双一流",
        },
        {
          id: 4,
          name: "985平台",
        },
        {
          id: 5,
          name: "2011计划",
        },
        {
          id: 6,
          name: "普通高校",
        },
        {
          id: 0,
          name: "无",
        },
      ],
      checkboxGroup3: [],
      mode: [
        {
          id: 1,
          name: "全日制",
        },
        {
          id: 2,
          name: "非全日制",
        },
      ],
      checkboxGroup4: [],
      interview: [
        {
          id: 1,
          name: "有提面",
        },
        {
          id: 0,
          name: "无提面",
        },
        {
          id: 3,
          name: "必须提面",
        },
      ],
      checkboxGroup5: [],
      adjust: [
        {
          id: 1,
          name: "可调剂",
        },
        {
          id: 0,
          name: "无调剂",
        },
      ],
      collegelist: [],
      types: [],
      searchVal: "",
      order: true,
      order1: false,
      totalnum: "",
      page: 1,
      province: "",
      city: "",
      newCity: [],
      code: "",
      compony: [],
      inputForm: false,
      isDisable: false,
      isRefreshBool: true,
      dixian: false,
    };
  },
  methods: {
    showInput() {
      this.show = !this.show;
    },
    showPopup() {
      this.Popup = true;
    },
    detia(id) {
      this.$router.push({
        path: "/detia",
        query: {
          id: id,
        },
      });
    },
    // sleep(d) {
    //       return new Promise((resolve) => setTimeout(resolve, d))
    //      },
    // ---根据name搜索
    async search() {
      if (this.isDisable) {
        return;
      }

      this.isDisable = true;
      if (!this.searchVal == "") {
        const ful = await CollegeList({
          name: this.searchVal,
        });
        //  await this.sleep(3000)

        ful.data.data.forEach(function (val) {
          if (val.enroll_types != null || val.project_attr != null) {
          val.enroll_types = val.enroll_types.split(",");
          val.project_attr = val.project_attr.split(",");
        }
        });
        this.collegelist = ful.data.data;
      }

      this.isDisable = false;
    },
    //---价格排序 ---升序
    async upchange() {
      this.xuefeiIcon = "http://www.xiongsongedu.com/uploads/20210707/7d1f109b811b9661ef17343f943e55cf.png"
      this.order1 = true;
      this.order = false;
      var a = this.checkboxGroup1.toString();
      var b = this.checkboxGroup2.toString();
      var c = this.checkboxGroup3.toString();
      var d = this.checkboxGroup4.toString();
      var e = this.checkboxGroup5.toString();
      var f = this.province.toString();
      var g = this.city.toString();
      try {
        const res = await CollegeList({
          order: "min_tuition",
          sort: "asc",
          major_type: a,
          college_attr_values: b,
          enroll_arrt_values: c,
          is_interview: d,
          is_adjust: e,
          province: f,
          city: g,
        });
        res.data.data.forEach(function (val) {
          if (val.enroll_types != null || val.project_attr != null) {
            val.enroll_types = val.enroll_types.split(",");
            val.project_attr = val.project_attr.split(",");
          }
        });
        this.collegelist = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    //---降序
    async downchange() {
      this.order1 = false;
      this.order = true;
      var a = this.checkboxGroup1.toString();
      var b = this.checkboxGroup2.toString();
      var c = this.checkboxGroup3.toString();
      var d = this.checkboxGroup4.toString();
      var e = this.checkboxGroup5.toString();
      var f = this.province.toString();
      var g = this.city.toString();
      try {
        const res = await CollegeList({
          order: "max_tuition",
          sort: "desc",
          major_type: a,
          college_attr_values: b,
          enroll_arrt_values: c,
          is_interview: d,
          is_adjust: e,
          province: f,
          city: g,
        });
        res.data.data.forEach(function (val) {
          if (val.enroll_types != null || val.project_attr != null) {
            val.enroll_types = val.enroll_types.split(",");
            val.project_attr = val.project_attr.split(",");
          }
        });
        this.collegelist = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },

    //专业
  async  areaCheckedHandle(e) {
       console.log(this.option1[e].text)
         let major = this.option1[e].text
       const res = await CollegeList({
         major_type:major
       })
        res.data.data.forEach(function (val) {
        if (val.enroll_types != null || val.project_attr != null) {
          val.enroll_types = val.enroll_types.split(",");
          val.project_attr = val.project_attr.split(",");
        }
      });

      this.collegelist = res.data.data;
    },

    // 筛选 ---专业类别
    handleCheck(val) {
      this.checkboxGroup1 = val;
    },
    // ---院校性质
    handleNature(e) {
      this.checkboxGroup2 = e;
    },
    //---学习方式
    handleMode(val) {
      this.checkboxGroup3 = val;
    },
    // ---提前面试
    handleInterview(val) {
      this.checkboxGroup4 = val;
    },
    // ---调剂情况
    handleAdjust(val) {
      this.checkboxGroup5 = val;
    },
    //---地区选择
    selectChange(value) {
      this.province = value;
      if (this.value != "") {
        this.inputForm = true;
      } else {
        this.inputForm = false;
      }
      this.compony.forEach((a) => {
        if (value == a.code) {
          this.newCity = a.children;
        }
      });
      this.city = "";
    },
    //---市区
    cityChange(value) {
      this.city = value;
    },

    // 筛选 ---确定
    async sure() {
      var a = this.checkboxGroup1.toString();
      var b = this.checkboxGroup2.toString();
      var c = this.checkboxGroup3.toString();
      var d = this.checkboxGroup4.toString();
      var e = this.checkboxGroup5.toString();
      var f = this.province.toString();
      var g = this.city.toString();
      try {
        const data = await CollegeList({
          major_type: a,
          college_attr_values: b,
          enroll_arrt_values: c,
          is_interview: d,
          is_adjust: e,
          province: f,
          city: g,
        });
        data.data.data.forEach(function (val) {
          if (val.enroll_types != null || val.project_attr != null) {
            val.enroll_types = val.enroll_types.split(",");
            val.project_attr = val.project_attr.split(",");
        }
        });
        this.collegelist = data.data.data;
      } catch (e) {
        console.log(e);
      }
      this.Popup = false;
    },
    again() {
      this.checkboxGroup1 = [];
      this.checkboxGroup2 = [];
      this.checkboxGroup3 = [];
      this.checkboxGroup4 = [];
      this.checkboxGroup5 = [];
    },

    // ---下拉加载
    async handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      // console.log(scrollTop + windowHeight + "--" + scrollHeight);
      if (scrollTop + windowHeight >= scrollHeight - 1 && this.isRefreshBool) {
        // false防止refresh()加载数据函数多次触发
        this.isRefreshBool = false;
        var a = this.checkboxGroup1.toString();
        var b = this.checkboxGroup2.toString();
        var c = this.checkboxGroup3.toString();
        var d = this.checkboxGroup4.toString();
        var e = this.checkboxGroup5.toString();
        var f = this.province.toString();
        var g = this.city.toString();
        this.page++;
        const res = await CollegeList({
          page: this.page,
          limit: 20,
          major_type: a,
          college_attr_values: b,
          enroll_arrt_values: c,
          is_interview: d,
          is_adjust: e,
          province: f,
          city: g,
          name: this.searchVal,
        });
        res.data.data.forEach(function (val) {
          if (val.enroll_types != null || val.project_attr != null) {
            val.enroll_types = val.enroll_types.split(",");
            val.project_attr = val.project_attr.split(",");
          }
        });
        this.collegelist = [...this.collegelist, ...res.data.data];
        this.dixian = true;
      }
      this.isRefreshBool = true;

      // if (scrollTop + windowHeight >= scrollHeight) {
      //   if (this.page > this.totalnum) {
      //     return;
      //   } else {

      //     // console.log(res)
      //   }
      // }
    },

    //---倒计时
  },
  async mounted() {
    this.compony = ary;
    try {
      const res = await CollegeList({
        major_type: "MBA",
      });
      res.data.data.forEach(function (val) {
        if (val.enroll_types != null || val.project_attr != null) {
          val.enroll_types = val.enroll_types.split(",");
          val.project_attr = val.project_attr.split(",");
        }
      });

      this.collegelist = res.data.data;

      //--- 搜索数据条数及变换成页数
      let dataTotal = res.data.count;
      //---- 搜索数据取整
      let dataInt = parseInt(dataTotal / 20);
      //---- 搜索数据取余
      let dataSur = dataTotal % 10;
      if (dataSur > 0) {
        dataInt += 1;
      }
      this.totalnum = dataInt;
      // console.log("总页数："+dataInt);
    } catch (e) {
      console.log(e);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
.search {
  height: 0.8rem;
  padding: 0.2rem 0;
  background: #fafafa;
}

.search .van-icon {
  top: -0.62rem;
  right: -85%;
}

.search input {
  width: 92%;
  display: block;
  margin: 0 auto;
  height: 0.8rem;
  border-radius: 0.5rem;
  border: 1px solid #999999;
  padding-left: 0.4rem;
  box-sizing: border-box;
  font-size: 0.28rem;
  color: #333333;
  background: #fafafa;
}
input::-webkit-input-placeholder {
  color: #333333;
  font-size: 0.28rem;
}

.chooice {
  display: flex;
  background-color: #fff;
  align-items: center;
  margin: 0.35rem 0.3rem 0.2rem 0.1rem;
  justify-content: space-between;
}

.zhuanye {
  width: 2rem;
}

/* 地区 */
.address {
  width: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address span {
  display: block;
  font-size: 0.28rem;
  color: #999999;
  padding-left: 0.08rem;
}
.Chleft {
  display: flex;
}
.chooiceLbor {
  display: flex;
  align-items: center;
}

.chooice_l {
  width: 1.5rem;
  box-shadow: 0 0 0 0;
  /* border: 1px solid firebrick; */
}

.chooice_c {
  width: 2.8rem;
  box-sizing: border-box;
  padding-left: 0.3rem;
}
.chooice_c span {
  font-size: 0.28rem;
  color: #999999;
}
.chooice_r {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.chooice_r span {
  display: block;
  color: #999999;
  padding-left: 0.08rem;
}
.chooice_r p {
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  font-size: 0.28rem;
}
.colleges {
  margin: 0rem 0.2rem 2rem 0.2rem;
}
.school {
  border-bottom: 1px solid #f5f5f5;
  padding: 0.3rem 0;
}
.collegesBor {
  display: flex;
  padding: 0rem 0.1rem;
  align-items: center;
}
.colleges_logo img {
  height: 1rem;
}
.colleges_t {
  margin-left: 0.3rem;
}
.colleges_t h2 {
  font-size: 0.32rem;
  color: #333333;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.colleges_t > span {
  display: block;
  font-size: 0.3rem;
  font-family: PingFang-SC-Regular;
  color: #333333;
  margin-top: 0.1rem;
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.2rem;
  padding-left: 0.1rem;
}
.lable_bor {
  display: flex;
}
.label_l,
.label_r {
  display: flex;
  align-items: center;
}
.label_l span {
  /* display: block;
  font-size: 0.2rem;
  color: #666666;
  background: #f7f7f7;
  margin-right: 0.15rem;
  padding: 0.1rem 0.15rem;
  border-radius: 0.2rem; */

  display: block;
  font-size: 0.2rem;
  color: #909ba4;
  background: #f2f1f6;
  margin-right: 0.12rem;
  padding: 0.1rem 0.1rem;
  border-radius: 0.06rem;
}
.label_r {
  margin-right: 0.15rem;
}
.label_r span {
  margin-left: 0.05rem;
  font-size: 0.21rem;
  color: #666666;
}

/* s筛选 */
.Popup {
  padding: 0.5rem 0;
  box-sizing: border-box;
  border-radius: 0.4rem 0.4rem 0 0;
}

.Popup .title {
  padding-top: 0.1rem;
  text-align: center;
  color: #000000;
  font-size: 0.3rem;
  letter-spacing: 0.02rem;
}
.category {
  margin-top: 0.4rem;
}
.category h2 {
  color: #333333;
  letter-spacing: 0.03rem;
  margin: 0.3rem 0.3rem 0.1rem 0.4rem;
  font-weight: bold;
  font-size: 0.3rem;
}
.btn {
  display: flex;
  flex-wrap: wrap;
}
.btn button {
  color: #333333;
  display: block;
  width: 2.1rem;
  background: #f6f6f6;
  font-size: 0.28rem;
  border: none;
  height: 0.7rem;
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  letter-spacing: 0.02rem;
  margin-left: 0.28rem;
}
.button {
  color: #333333;
  display: block;
  width: 2.1rem;
  background: #f6f6f6;
  font-size: 0.28rem;
  border: none;
  height: 0.7rem;
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  letter-spacing: 0.02rem;
  margin-left: 0.28rem;
}
.button.active {
  background: #00bd81;
  color: #fff;
}

.FOTBtn {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-top: 0.3rem;
}
.FOTBtn button:nth-child(1) {
  display: block;
  width: 3.35rem;
  border: none;
  background: #00bd81;
  height: 0.8rem;
  border-radius: 0.4rem 0 0 0.4rem;
}
.FOTBtn button:nth-child(2) {
  display: block;
  width: 3.35rem;
  border: none;
  background: #2ce0a7;
  height: 0.8rem;
  border-radius: 0 0.4rem 0.4rem 0;
  margin-left: 0.1rem;
}

.dixian {
  font-size: 0.25rem;
  color: #999999;
  text-align: center;
  left: 3rem;
  margin-top: 0.4rem;
}
</style>
<style>
.diqu {
  width: 100%;
  margin-top: 20px;
}
.sheng {
  display: flex;
}
.diqu .el-input {
  width: 82%;
}
.diqu .el-form-item__label {
  width: 1.5rem;
  text-align: center;
  padding: 0;
}

.zhuanye .van-dropdown-menu__title {
  font-size: 0.28rem;
  color: #999999;
}
</style>